<template>
  <div class="ds-wrapper">
    <div class="payment-top-card pt-20 pr-7.25 pb-4 pl-8 rounded-b-4xl md-rounded-b-4xl lg-rounded-b-4xl">
      <div class="cursor-pointer flex gap-x-4 items-center justify-center" v-if="pageBranding && pageBranding.isHPP">
        <S3ImageGenerator v-if="pageBranding.merchantLogo" :document="pageBranding.merchantLogo" :is-base64="false" :key="pageBranding.merchantLogo" class="w-20 h-8 md:w-24 md:h-10 object-contain" />
        <div class="logo-divider flex items-center" v-if="pageBranding.cobrandLogo"></div>
        <S3ImageGenerator v-if="pageBranding.cobrandLogo" :document="pageBranding.cobrandLogo" :is-base64="false" :key="pageBranding.cobrandLogo" class="w-20 h-8 md:w-24 md:h-10 object-contain" />
      </div>

      <div class="cursor-pointer gap-x-4 flex items-center justify-center" v-else-if="Object.keys(pageDetail).length">
        <template v-if="checkLogoInArray">
          <img v-if="logoImage" :src="logoImage" alt="img" class="responsive"/>
          <div class="logo-divider flex items-center" v-if="cobrandLogo">&nbsp;</div>
          <img v-if="cobrandLogo" :src="cobrandLogo" alt="img" class="responsive"/>
        </template>
        <template v-else-if="logoImage || cobrandLogo">
          <S3ImageGenerator v-if="logoImage" :document="logoImage" :is-base64="!(Object.keys(pageDetail).length && pageDetail.logo)" :key="logoImage" class="w-20 h-8 md:w-24 md:h-10 object-contain" />
          <div class="logo-divider flex items-center" v-if="cobrandLogo">&nbsp;</div>
          <S3ImageGenerator v-if="cobrandLogo" :document="cobrandLogo" :is-base64="false" :key="cobrandLogo" class="w-20 h-8 md:w-24 md:h-10 object-contain" />
        </template>
        <template v-else>
          <div class="site-logo">
            <img :src="defaultLogo" :alt="appName" />
          </div>
        </template>
      </div>
      <h1 class="pt-6 pb-24 text-primary text-center" v-if="showAmount">Request for ${{ addZeroes(paymentRequestDetail.amount.$numberDecimal) }}</h1>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      appName: process.env.VUE_APP_NAME || "FlipPay",
      cobrandLogo: "",
      cobrandLogoId: "",
      defaultLogo: "",
      flipLogo: require("@/assets/images/logo/flippay-logo.svg"),
      logoImage: "",
      merchantLogoId: "",
      prId: "",
      relloLogo: require("@/assets/images/logo/rellopay-logo.svg"),
      serverUrl: process.env.VUE_APP_API_URL,
      showAmount: false,
    };
  },
  props: {
    paymentRequestDetail: { type: Object },
    pageBranding: { type: Object },
    logo: { type: String },
    navbarType: {
      type: String,
      required: true,
    },
  },
  components: {
    S3ImageGenerator
  },
  methods: {
    ...mapActions("logo", ["fetchLogoByObjectId"]),

    formattedUrl(url) {
      if (!url) {
        return "";
      } else {
        return url.includes("http") ? url : `https://${url}`;
      }
    },

    async getLogoByObjectID(merchantLogoId) {
      await this.fetchLogoByObjectId(merchantLogoId).then((res) => {
        if (res.data.data.logoImage) {
          this.logoImage = res.data.data.logoImage;
        }
      });
    },

    async getCobrandLogoByObjectID(merchantLogoId) {
      await this.fetchLogoByObjectId(merchantLogoId).then((res) => {
        if (res.data.data.logoImage) {
          this.cobrandLogo = res.data.data.logoImage;
        }
      });
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },

  created() {
    if (Object.keys(this.paymentRequestDetail).length > 0) {

      if (this.paymentRequestDetail.paymentPage.pageBranding && this.paymentRequestDetail.paymentPage.pageBranding.merchantLogo) {
        this.merchantLogoId = this.paymentRequestDetail.paymentPage.pageBranding.merchantLogo;
        this.getLogoByObjectID(this.merchantLogoId);
      }

      if (this.paymentRequestDetail.paymentPage.pageBranding && this.paymentRequestDetail.paymentPage.pageBranding.cobrandLogo) {
        this.cobrandLogoId = this.paymentRequestDetail.paymentPage.pageBranding.cobrandLogo;
        this.getCobrandLogoByObjectID(this.cobrandLogoId);
      }

      if (this.paymentRequestDetail.paymentPage.pageBranding.logos.length > 0) {
        this.logoImage = this.paymentRequestDetail.paymentPage.pageBranding.logos[0];

        if (this.paymentRequestDetail.paymentPage.pageBranding.logos.length >= 1) {
          this.cobrandLogo = this.paymentRequestDetail.paymentPage.pageBranding.logos[1];
        }
      }

      this.defaultLogo = this.appName.toLowerCase() == "flippay" ? this.flipLogo : this.relloLogo;
    }

    this.showAmount = (["paymentPage", "payments"].includes(this.$route.name));
  },

  computed: {
    pageDetail() {
      return this.$store.state.payment.page ? this.$store.state.payment.page : {};
    },

    checkLogoInArray() {
      const paymentPage = this.paymentRequestDetail.paymentPage;
      return (paymentPage.pageBranding.logos && paymentPage.pageBranding.logos.length > 0);
    },

    navbarColor() {
      let color = "#fff";

      if (this.navbarType === "sticky") {
        color = "#f7f7f7";
      } else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },

    isThemedark() {
      return this.$store.state.theme;
    },

    navbarStyle() {
      return this.navbarType === "static" ? { transition: "all .25s ease-in-out" } : {};
    },

    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static" ? null : "d-theme-dark-light-bg";
    },

    scrollY() {
      return this.$store.state.scrollY;
    },

    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
  }
};
</script>

